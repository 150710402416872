<template>
  <div fluid class="error-page">
    <div class="wrapper">
      <div class="logo-wrapper">
        <v-img
          v-if="isLogoBase64 || !customLogo"
          :width="$vuetify.breakpoint.mobile ? 171 : 200"
          :height="$vuetify.breakpoint.mobile ? 60 : 60"
          contain
          :src="customLogo ? customLogo : require(`@/assets/logo${isDark ? '-white' : ''}.svg`)"
          class="mb-10"
        ></v-img>
        <div
          v-else v-html="customLogo" class="mb-10 d-flex"
          :width="$vuetify.breakpoint.mobile ? 171 : 200"
          :height="$vuetify.breakpoint.mobile ? 60 : 60"
        />
      </div>
      <div class="mt-auto">
        <div class="card">
            <span class="error-logo">
              <dataliteratorNoResultIllustration/>
            </span>
          <p class="error-text">{{ $t('heading.notFoundPage.title') }}</p>
          <p class="error-subtext">{{ $t('heading.notFoundPage.info') }}</p>
          <v-btn elevation="0" color="primary" :to="{ name: 'Home' }" :large="true">
              <span class="font-weight-600">
                {{ $t('button.home.back') }}
              </span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataliteratorNoResultIllustration from '../components/illustrations/dataliterator-no-result-illustration.vue';

export default {
  components: {
    dataliteratorNoResultIllustration
  },
  data() {
    return {};
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
    customLogo: function () {
      if (!this.$store.state.ThemeModule.logo) {
        return false;
      }

      return this.$store.state.ThemeModule.logo;
    },
    isLogoBase64: function () {
      return this.customLogo && this.customLogo.trim().startsWith('data:image/');
    },
  },
};
</script>

<style scoped lang="scss">
.error-page {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  padding: 24px;

  .logo-wrapper {
    margin-top: 24px;
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 100%;
    flex-grow: 0;

    .logo-title {
      font-size: $font-size-h2;
      font-weight: $font-weight-medium;
      color: white;
    }
  }

  .wrapper {
    max-height: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card {
    align-self: center;
    background-color: white;
    border-radius: 4px;
    max-width: 480px;
    width: 100%;
    padding: 16px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    .error-logo {
      margin-bottom: 40px;
      width: 64px;
      height: 64px;
    }

    .error-text {
      color: var(--v-gray-darken2);
      text-align: center;
      font-size: $font-size-h1 !important;
      line-height: $line-height-h1;
      font-weight: $font-weight-bold;
    }

    .error-subtext {
      text-align: center;
      color: var(--v-gray-darken1);
      font-size: $font-size-base;
      line-height: $line-height-base;
      max-width: 278px;
      margin-bottom: 40px;
    }
  }
}
</style>
